<template>
  <div>
    <LikeButton v-if="!liked" @onClick="$emit('onLike')" :disable="disable"/>
    <UnlikeButton v-else @onClick="$emit('onUnlike')" :disable="disable"/>
  </div>
</template>

<script>
export default {
  name: 'OrganizzeReactionMenu',
  props: {
    liked: {
      type: Boolean,
      default: false,
      required: true
    },

    disable: {
      type: Boolean,
      default: false
    }
  },
  directives: {},
  components: {
    LikeButton: () => import('@/views/components/organizze/likeButton'),
    UnlikeButton: () => import('@/views/components/organizze/unlikeButton')
  },
  data: () => ({}),
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
  validations () { return {} }
}
</script>